<template>
  <div class="answer-content">
    <div class="main-wrapper-header">
      <div class="header-left">
        <el-breadcrumb separator=">" style="margin-left: 4px" >
          <el-breadcrumb-item>实操训练</el-breadcrumb-item>
          <el-breadcrumb-item>{{$route.query.trainName}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{$route.query.num}}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="header-right">
        <el-button @click="toBack" type="primary">返回</el-button>
      </div>
    </div>
    <div class="main-wrapper-content">
      <CustomPageDetail :detail-data="detailData" v-if="moduleId !== 11 && Object.keys(detailData).length > 0" />
      <StoreSetPageDetail :detail-data="detailData" v-if="moduleId === 11 && Object.keys(detailData).length > 0" />
      <div v-if="Object.keys(detailData).length === 0" style="text-align: center;line-height: 60px">暂无数据</div>
    </div>
  </div>
</template>

<script>
import CustomPageDetail from '@/components/student/train/CustomPageDetail.vue'
import StoreSetPageDetail from '@/components/student/train/StoreSetPageDetail.vue'
import {stuOpTrainData} from '@/utils/apis'
export default {
  name: "OnlineStoreDetail",
  components:{
    CustomPageDetail,
    StoreSetPageDetail
  },
  data(){
    return {
      moduleId:Number(this.$route.query.moduleId) || null,
      detailData:{},
    }
  },
  mounted() {
    this.getStuOpTrainData()
  },
  methods:{
    getStuOpTrainData(){
      let params = {
        train_id:this.$route.query.trainId
      }
      stuOpTrainData(params).then((res)=>{
        this.detailData = res.data;
      }).catch((err)=>{
        console.log('err',err)
      })
    },
    toBack(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-breadcrumb{
  .el-breadcrumb__item{
    .el-breadcrumb__inner{
      color: #999999;
    }
    &:last-of-type{
      .el-breadcrumb__inner{
        color: #333;
      }
    }
  }
  .el-breadcrumb__separator{
    color: #999;
    font-weight: 500;
  }
}
.answer-content{
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 20px;
  .main-wrapper-header{
    padding: 0px 0 14px;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #F1F5FF;
    .header-left{
      display: flex;
      align-items: center;
      span{
        line-height: 1;
      }
    }
  }
  .main-wrapper-content{
    flex: 1;
    height: 1%;
    display: flex;
    flex-direction: column;
  }
}
</style>